import request from '@/utils/requestZs'

export function get_imgId(params) {
  return request({
    url: 'personal/Certification/searchCertification',
    method: 'get',
    params
  })
}
export function get_img(params) {
  return request({
    url: 'personal/certification/downCertification',
    method: 'get',
    params
  })
}
export function get_infos(params) {
  return request({
    url: 'personal/Certification/getCertificationInfo',
    method: 'get',
    params
  })
}
// 获取左侧菜单
export function getMenuApi(params) {
  return request({
    url: 'zkb/index/menu',
    method: 'get',
    params
  })
}
// 证书查询列表
export function searchCertification(params) {
  return request({
    url: 'personal/Certification/searchCertification',
    method: 'get',
    params
  })
}

// 版块分类
export function getOriginalPrimary(params) {
  return request({
    url: '/home/index/getOriginalPrimary',
    method: 'get',
    params
  })
}
